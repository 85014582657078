import { configureStore } from '@reduxjs/toolkit'
import headerSlice from './slices/headerSlice'
import modalSlice from './slices/modalSlice'
import rightDrawerSlice from './slices/rightDrawerSlice'
import leadsSlice from '../features/leads/leadSlice'
import estimateButtonSlice from './slices/estimateButtonSlice'
import authSlice from './slices/authSlice'
import userSlice from './slices/userSlice'
import tabSlice from './slices/tabSlice'
import jurySlice from './slices/jurySlice'
import adminSlice from './slices/adminSlice'
import watcherSlice from './slices/watcherSlice'

const combinedReducer = {
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  estimateButton: estimateButtonSlice,
  auth: authSlice,
  user: userSlice,
  tabs: tabSlice,
  participants: jurySlice,
  admin: adminSlice,
  watcher: watcherSlice
}

export const store = configureStore({
  reducer: combinedReducer,
})
