import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import JuryRepository from '../../api/repositories/JuryRepository'
import { router } from "../../routes";

export const getParticipants = createAsyncThunk(
    'jury/participants',
    async (payload) => {
        try {
            const response = await JuryRepository.getParticipants(payload)
            return response.data.data
        } catch (e) {
            const response = await JuryRepository.getParticipants(payload)
            return response.data.data
        }
    }
)

export const getOneParticipant = createAsyncThunk(
    'jury/participant',
    async (payload) => {
        try {
            console.log('getOneParticipant', payload);
            const response = await JuryRepository.getOneParticipant(payload)
            return response.data.data
        } catch (e) {
            return e;
        }
    }
)

export const putEvaluateParticipant = createAsyncThunk(
    'jury/evaluate/participant',
    async (payload) => {
        try {
            const response = await JuryRepository.putEvaluateParticipant(payload)
            return response.data.data
        } catch (e) {
            console.log(e.response)
        }
    }
)

export const getCommonProtocol = createAsyncThunk(
    'jury/common/protocol',
    async (payload) => {
        try {
            const response = await JuryRepository.getCommonProtocol(payload)
            return response.data.data
        } catch (e) {
            console.log(e.response)
            return e;
        }
    }
)

const initialState = {
    participants: [],
    protocol: [],
    participant: {},
    evaluation: null,
    isLoading: false,
}


export const jurySlice = createSlice({
    name: 'jury/protocol',
    initialState,
    reducers: {
        setEvaluation: (state, { payload }) => {
            state.evaluation = payload;
        },
        clearEvaluation: (state) => {
            state.evaluation = null;
        }
    },
    extraReducers: (builder) => {
        // Получение списка участников
        builder
            .addCase(getParticipants.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getParticipants.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.participants = payload
            })
        // Получение одного участника
        builder
            .addCase(getOneParticipant.pending, (state, { payload }) => {
                state.isLoading = true
            })
            .addCase(getOneParticipant.fulfilled, (state, { payload }) => {
                state.participant = payload;
                state.isLoading = false;
            })
            .addCase(getOneParticipant.rejected, (state) => {
                state.isLoading = false;
            })
        // Отправка оценок
        builder
            .addCase(putEvaluateParticipant.pending, (state, { payload }) => {
                state.isLoading = true
            })
            .addCase(putEvaluateParticipant.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                router.navigate('/jury/evaluations')
            })
        // Получение общего протокола
        builder
            .addCase(getCommonProtocol.pending, (state, { payload }) => {
                state.isLoading = true
            })
            .addCase(getCommonProtocol.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.protocol = payload
            })
    }

})

export const { setOneParticipant, setEvaluation } = jurySlice.actions
export default jurySlice.reducer 