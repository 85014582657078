import { createSlice } from '@reduxjs/toolkit'

export const tabSlice = createSlice({
    name: 'tabs',
    initialState: {
        tabIndex: 1,
        tabValue: 'Повышение эффективности ресурсов'
    },
    reducers: {
        setTab: (state, { payload }) => {
            state.tabIndex = payload.tabIndex
            state.tabValue = payload.tabValue
        }
    }
})

export const { setTab } = tabSlice.actions
export default tabSlice.reducer