import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AuthRepository from "../../api/repositories/AuthRepository";
import { router } from '../../routes/index'
import {routeHandler} from "../../utils/functions";

const initialState = {
    error: null,
    isAuth: localStorage.getItem('access_token') !== null,
    isLoading: false,
}

export const login = createAsyncThunk(
    'auth/login',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await AuthRepository.login(payload)
            return response.data.data
        } catch (e) {
            return rejectWithValue(e.response.data.error.message)
        }
    }
)

export const logout = createAsyncThunk(
    'auth/logout',
    async () => {
        try {
            const response = await AuthRepository.logout()
            return response.data
        } catch (e) {
            console.log(e.response)
        }

    }
)

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(login.fulfilled, (state, { payload }) => {
                state.isAuth = true;
                state.error = null;
                const { access_token, user } = payload;
                localStorage.setItem('access_token', access_token);
                localStorage.setItem('role', user.role);
                state.isLoading = false;
                router.navigate(`${user.role}/${routeHandler(user.role)}`);
            })
            .addCase(login.rejected, (state, { payload }) => {
                state.isLoading = false;
                localStorage.removeItem('access_token');
                state.error = payload
            });
        builder
            .addCase(logout.fulfilled, (state, { payload }) => {
                state.isAuth = false
                state.isLoading = false;
                state.user = {}
                localStorage.removeItem('access_token')
                localStorage.removeItem('role')
                router.navigate('/login')
            });


    },
})
export default authSlice.reducer