import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UserRepository from '../../api/repositories/UserRepository'
import { router } from '../../routes/index'
import { routeHandler } from "../../utils/functions";

export const getMe = createAsyncThunk(
    'user/me',
    async () => {
        try {
            const response = await UserRepository.getMe()
            return response.data.data;
        } catch (e) {
            return e;
        }
    }
)

const initialState = {
    isLoading: false,
    role: localStorage.getItem('role'),
    user: {}
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getMe.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getMe.fulfilled, (state, { payload }) => {
                state.role = payload.role;
                state.user = payload;
                state.isLoading = false;
                if(!localStorage.getItem('role')) {
                    localStorage.setItem('role', payload.role)
                }
            })

    }
})

export default userSlice.reducer