import instance from "../Service";

export default class JuryRepository {
    static async getParticipants(params) {
        return instance.get(`/jury/evaluations/participants?sections[]=${params}`)
    }
    static async getOneParticipant(params) {
        return instance.get(`/jury/evaluations/participant/${params}`)
    }
    static async putEvaluateParticipant(params) {
        return instance.put(`/jury/evaluations/participant/${params.id}`, params.data)
    }
    static async getCommonProtocol(params) {
        return instance.get(`/jury/evaluations/common?sections[]=${params}`)
    }
}