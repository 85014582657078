import { createSlice } from '@reduxjs/toolkit'

export const estimateButtonSlice = createSlice({
    name: 'estimateButton',
    initialState: {
        disabledButton: false
    },
    reducers : {
        setDisabled: (state) => {
            state.disabledButton = true
        }
    }
})

export const {setDisabled} = estimateButtonSlice.actions

export default estimateButtonSlice.reducer