import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import WatcherRepository from "../../api/repositories/WatcherRepository";

const initialState = {
    isLoading: false,
    participants: [],
    participant: null,
    juries: [],
    jury: null,
    commonProtocol: [],
    oneJuryProtocol: [],
    oneParticipantProtocol: [],
    evaluations: null,
    urlWatcher: null
}


export const getParticipants = createAsyncThunk(
    'watcher/getParticipants',
    async () => {
        try {
            const response = await WatcherRepository.getParticipants()
            return response.data.data
        } catch (e) {
            console.log(e)
        }

    }
)

export const getOneParticipant = createAsyncThunk(
    'watcher/getOneParticipant',
    async (payload) => {
        try {
            const response = await WatcherRepository.getOneParticipant(payload)
            return response.data.data
        } catch (e) {
            console.log(e)
        }

    }
)

export const getJuries = createAsyncThunk(
    'watcher/getJuries',
    async () => {
        try {
            const response = await WatcherRepository.getJuries()
            return response.data.data
        } catch (e) {
            console.log(e)
        }

    }
)

export const getOneJury = createAsyncThunk(
    'watcher/getOneJury',
    async (payload) => {
        try {
            const response = await WatcherRepository.getOneJury(payload)
            return response.data.data
        } catch (e) {
            console.log(e)
        }

    }
)

export const getCommonProtocol = createAsyncThunk(
    'watcher/getCommonProtocol',
    async (payload) => {
        try {
            const response = await WatcherRepository.getCommonProtocol(payload)
            return response.data.data
        } catch (e) {
            console.log(e)
        }

    }
)

export const getOneParticipantProtocol = createAsyncThunk(
    'watcher/getOneParticipantProtocol',
    async (payload) => {
        try {
            const response = await WatcherRepository.getOneParticipantProtocol(payload)
            return response.data.data
        } catch (e) {
            console.log(e)
        }

    }
)

export const getOneJuryProtocol = createAsyncThunk(
    'watcher/getOneJuryProtocol',
    async (payload) => {
        try {
            const response = await WatcherRepository.getOneJuryProtocol(payload)
            return response.data.data
        } catch (e) {
            console.log(e)
        }

    }
)


export const exportCommonProtocolWatcher = createAsyncThunk(
    'watcher/exportCommonProtocolWatcher ',
    async () => {
        try {
            const response = await WatcherRepository.exportCommonProtocolWatcher()
            return response.data.data.url
        } catch (e) {
            console.log(e)
        }

    }
)

export const exportZipProtocolWatcher = createAsyncThunk(
    'watcher/exportZipProtocolWatcher ',
    async () => {
        try {
            const response = await WatcherRepository.exportZipProtocolWatcher()
            return response.data.data.url
        } catch (e) {
            console.log(e)
        }

    }
)

export const exportJuryProtocolWatcher = createAsyncThunk(
    'watcher/exportJuryProtocolWatcher ',
    async (payload) => {
        try {
            const response = await WatcherRepository.exportJuryProtocolWatcher(payload)
            return response.data.data.url
        } catch (e) {
            console.log(e)
        }
    }
)

export const getEvaluations = createAsyncThunk(
    'watcher/getEvaluations',
    async (payload) => {
        try {
            const response = await WatcherRepository.getEvaluations(payload)
            return response.data.data
        } catch (e) {
            console.log(e)
        }

    }
)


const watcherSlice = createSlice({
    name: 'watcher',
    initialState,
    reducers: {
        clearEvalutions: (state, { payload }) => {
            state.evaluations = null
        },
        clearUrl: (state, { payload }) => {
            state.urlWatcher = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getParticipants.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getParticipants.fulfilled, (state, { payload }) => {
                state.participants = payload
                state.isLoading = false
            })
        builder
            .addCase(getOneParticipant.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getOneParticipant.fulfilled, (state, { payload }) => {
                state.participant = payload
                state.isLoading = false

            })
        builder
            .addCase(getJuries.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getJuries.fulfilled, (state, { payload }) => {
                state.juries = payload
                state.isLoading = false

            })
        builder
            .addCase(getOneJury.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getOneJury.fulfilled, (state, { payload }) => {
                state.jury = payload
                state.isLoading = false

            })
        builder
            .addCase(getCommonProtocol.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getCommonProtocol.fulfilled, (state, { payload }) => {
                state.commonProtocol = payload
                state.isLoading = false
            })
        builder
            .addCase(getOneJuryProtocol.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getOneJuryProtocol.fulfilled, (state, { payload }) => {
                state.oneJuryProtocol = payload
                state.isLoading = false
            })
        builder
            .addCase(getEvaluations.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getEvaluations.fulfilled, (state, { payload }) => {

                state.evaluations = payload
                state.isLoading = false
                return state
            })
        builder
            .addCase(getOneParticipantProtocol.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getOneParticipantProtocol.fulfilled, (state, { payload }) => {
                state.oneParticipantProtocol = payload
                state.isLoading = false
            })

        builder
            .addCase(exportCommonProtocolWatcher.pending, (state, { payload }) => {
                state.isLoading = true

            })
            .addCase(exportCommonProtocolWatcher.fulfilled, (state, { payload }) => {
                state.urlWatcher = payload
                state.isLoading = false
            })
        builder
            .addCase(exportZipProtocolWatcher.pending, (state, { payload }) => {
                state.isLoading = true
            })
            .addCase(exportZipProtocolWatcher.fulfilled, (state, { payload }) => {
                state.urlWatcher = payload
                state.isLoading = false
            })
        builder
            .addCase(exportJuryProtocolWatcher.pending, (state, { payload }) => {
                state.isLoading = true

            })
            .addCase(exportJuryProtocolWatcher.fulfilled, (state, { payload }) => {
                state.urlWatcher = payload
                state.isLoading = false
            })
    }
})

export default watcherSlice.reducer
export const { clearEvalutions, clearUrl } = watcherSlice.actions