import { useEffect, memo } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';

import { refereeRoutes, adminRoutes, watcherRoutes } from '../routes/sidebar'

import { removeNotificationMessage } from "../app/slices/headerSlice"
import { getMe } from "../app/slices/userSlice";

import Header from "./Header";
import LeftSidebar from "./LeftSidebar"
import ModalLayout from "./ModalLayout"

import 'react-notifications/lib/notifications.css';
import Preloader from "../components/Typography/Preloader";

const routes = (role) => {
  switch (role) {
    case 'jury':
      return refereeRoutes;
    case 'admin':
      return adminRoutes;
    case 'watcher':
      return watcherRoutes;
    default:
      break;
  }
}

function Layout() {
  const dispatch = useDispatch();
  const { isAuth } = useSelector(state => state.auth);
  const { isLoading, role } = useSelector(state => state.user)
  const { newNotificationMessage, newNotificationStatus } = useSelector(state => state.header)
  useEffect(() => {
    if (newNotificationMessage !== "") {
      if (newNotificationStatus === 1) NotificationManager.success(newNotificationMessage, 'Успешно')
      if (newNotificationStatus === 0) NotificationManager.error(newNotificationMessage, 'Ошибка')
      dispatch(removeNotificationMessage())
    }
  }, [newNotificationMessage])

  useEffect(() => {
    if(isAuth) {
      dispatch(getMe());
    }
  }, [isAuth]);

  return (
    <>
      {isLoading ? <Preloader /> : (
        <div className="drawer drawer-mobile">
          <input id="left-sidebar-drawer" type="checkbox" className="drawer-toggle" />
          <div className="drawer-content flex flex-col ">
            <Header role={role} />
            <main className="flex-1 overflow-y-auto pt-8 px-6  bg-base-200">
              <Outlet />
            </main>
          </div>
          <LeftSidebar routes={routes(role)} />
        </div>
      )}

      {/** Notification layout container */}
      <NotificationContainer />

      {/* Modal layout container */}
      <ModalLayout />
    </>
  )
}

export default memo(Layout)