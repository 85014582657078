import { NavLink, useLocation } from 'react-router-dom'
import SidebarSubmenu from './SidebarSubmenu'
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon'
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { getSettings, getToggle, setSettings, setToggle } from '../app/slices/adminSlice';

function LeftSidebar({ routes }) {
    const location = useLocation();
    const close = () => {
        document.getElementById('left-sidebar-drawer').click()
    }
    const { role } = useSelector(state => state.user)
    const { isShowProtocol } = useSelector(state => state.admin)
    const dispatch = useDispatch()

    const showProtocol = () => {
        dispatch(setToggle(!isShowProtocol))
        dispatch(setSettings(
            {
                name: "visible_protocol_to_jury",
                bool_value: !isShowProtocol
            }
        ))
    }

    useEffect(() => {
        dispatch(getSettings())
    }, [])
    return (
        <div className="drawer-side ">
            <label htmlFor="left-sidebar-drawer" className="drawer-overlay"></label>
            <ul className="menu w-60 bg-base-100">
                <button className="btn btn-ghost  btn-xs bg-base-300  btn-circle z-40 top-0 right-0 mt-4 mr-2 absolute lg:hidden" onClick={() => close()}>
                    <XMarkIcon className="h-5 inline-block w-5" />
                </button>

                <div className="font-semibold text-base flex items-center justify-center  h-18" style={{ marginBottom: "13px" }}>
                    <img className="w-32" src="/Gazprom_drill.png" alt="Gazprom Logo" />
                </div>
                {
                    routes && routes.map((route, k) => {
                        return (
                            <li onClick={() => close()} className="" key={k} >

                                {
                                    route.submenu ?
                                        <SidebarSubmenu {...route} /> :
                                        (<NavLink
                                            end
                                            to={route.path}
                                            className={({ isActive }) => `${isActive ? 'font-semibold  bg-base-200 text-base' : 'font-normal text-base'}`} >
                                            {route.icon} {route.name}
                                            {
                                                location.pathname === route.path ? (<span className="absolute inset-y-0 left-0 w-1 rounded-tr-md rounded-br-mdtext-lg"
                                                    aria-hidden="true"></span>) : null
                                            }
                                        </NavLink>)
                                }

                            </li>
                        )
                    })

                }
                {
                    role === 'admin'
                        ? <li>
                            <div className="form-control">
                                <label className="label  cursor-pointer flex items-center">
                                    <span className="label-text font-normal text-base mr-2">Показать протокол</span>
                                    <input type="checkbox"
                                        className="toggle toggle-secondary"
                                        checked={isShowProtocol}
                                        onChange={showProtocol}
                                    />
                                </label>
                            </div>
                        </li>
                        : null
                }

            </ul>




        </div>
    )
}

export default LeftSidebar