// All components mapping with path for internal routes
import { lazy } from 'react'
import {createBrowserRouter, Navigate} from 'react-router-dom';

import { adminRouter } from "./constants/adminRouter";
import { juryRouter } from "./constants/juryRouter";
import { watcherRouter } from "./constants/watcherRouter";
import {routeDetection, routeHandler} from '../utils/functions'
import Layout from "../containers/Layout";
import ErrorPage from "../pages/ErrorPage";
// import Layout from '../containers/Layout';

const Login = lazy(() => import('../pages/Login'))
const Page404 = lazy(() => import('../pages/404'))
const Home = lazy(() => import('../pages/Home'))

const role = localStorage.getItem('role');
export const router = createBrowserRouter([
  ...juryRouter, // jury pages
  ...adminRouter, // admin pages
  ...watcherRouter, // watcher pages
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: '*',
    element: <Page404 />,
  },
  {
    path: '/',
    element: <Navigate to={routeDetection()}/>,
  },
  {
    path: '/error-page',
    element: <ErrorPage/>,
  }
])
