import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import './index.css';
import App from './App';
import { store } from './app/store'
import { Provider } from 'react-redux'
import SuspenseContent from './containers/SuspenseContent';

Sentry.init({
  dsn: "https://d329ec7bb6a144febfda0e90257f9a96@sentry.truemachine.ru/109",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>

  <Provider store={store}>
    <Suspense fallback={<SuspenseContent />}>
      <App />
    </Suspense>
  </Provider>
  // </React.StrictMode>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
