import instance from "../Service";

export default class WatcherRepository {
    static async getParticipants() {
        return instance.get('/watcher/participants')
    }
    static async getOneParticipant(data) {
        return instance.get(`/watcher/participants/${data}`)
    }
    static async getJuries() {
        return instance.get('/watcher/users?roles[]=jury')
    }
    static async getOneJury(data) {
        return instance.get(`/watcher/users/${data}`)
    }
    static async getCommonProtocol(data) {
        return instance.get(`/watcher/evaluations/common?sections[]=${data}`)
    }
    static async getOneJuryProtocol(data) {
        return instance.get(`/watcher/evaluations/jury/${data.id}?sections[]=${data.section}`)
    }
    static async getEvaluations(data) {
        console.log('zapros', data)
        return instance.get(`/watcher/evaluations/participant/${data.participant_id}/jury/${data.id}`)
    }
    static async getOneParticipantProtocol(data) {
        return instance.get(`/watcher/evaluations/participant/${data.id}?sections[]=${data.section}`)
    }

    static async exportCommonProtocolWatcher() {
        return instance.get(`/watcher/evaluations/export/link/common`)
    }
    static async exportZipProtocolWatcher() {
        return instance.get(`/watcher/evaluations/export/link/zip`)
    }
    static async exportJuryProtocolWatcher(data) {
        return instance.get(`/watcher/evaluations/export/link/jury/${data}`)
    }
}

