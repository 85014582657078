import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AuthRepository from "../../api/repositories/AuthRepository";
import AdminRepository from "../../api/repositories/AdminRepository";

const initialState = {

    isShowProtocol: null,
    isLoading: false,
    adminParticipantId: null,
    adminJuryId: null,
    participants: [],
    participant: {},
    commonProtocol: [],
    juries: [],
    jury: {},
    users: [],
    user: {},
    oneJuryProtocol: [],
    oneParticipantProtocol: [],
    evaluations: null,
    url: null
}


export const getSettings = createAsyncThunk(
    'auth/getSettings',
    async () => {
        try {
            const response = await AuthRepository.getSettings()
            return response.data.data
        } catch (e) {
            console.log(e.response)
        }

    }
)

export const setSettings = createAsyncThunk(
    'auth/setSettings',
    async (payload) => {
        try {
            const response = await AdminRepository.setSettings(payload)
            return response.data.data
        } catch (e) {
            console.log(e.response)
        }

    }
)

export const getParticipants = createAsyncThunk(
    'admin/getParticipants',
    async () => {
        try {
            const response = await AdminRepository.getParticipants()
            return response.data.data
        } catch (e) {
            console.log(e.response)
        }

    }
)

export const deleteParticipant = createAsyncThunk(
    'admin/deleteParticipant',
    async (payload) => {
        try {
            const response = await AdminRepository.deleteParticipant(payload)
            return response.data.data
        } catch (e) {
            console.log(e.response)
        }

    }
)


export const deleteJury = createAsyncThunk(
    'admin/deleteJury',
    async (payload) => {
        try {
            const response = await AdminRepository.deleteJury(payload)
            return response.data.data
        } catch (e) {
            console.log(e.response)
        }

    }
)


export const showParticipant = createAsyncThunk(
    'admin/showParticipant',
    async (payload) => {
        try {
            const response = await AdminRepository.showParticipant(payload)
            return response.data.data
        } catch (e) {
            console.log(e.response)
        }

    }
)

export const showJury = createAsyncThunk(
    'admin/showJury',
    async (payload) => {
        try {
            const response = await AdminRepository.showJury(payload)
            return response.data.data
        } catch (e) {
            console.log(e.response)
        }

    }
)

export const getJuryList = createAsyncThunk(
    'admin/getJuryList',
    async () => {
        try {
            const response = await AdminRepository.getJuryList()
            return response.data.data
        } catch (e) {
            console.log(e.response)
        }

    }
)

export const getCommonProtocol = createAsyncThunk(
    'admin/common/protocol',
    async (payload) => {
        try {
            const response = await AdminRepository.getCommonProtocol(payload)
            return response.data.data
        } catch (e) {
            console.log(e.response)
        }
    }
)

export const getOneParticipantProtocol = createAsyncThunk(
    'admin/getOneParticipantProtocol',
    async (payload) => {
        try {
            const response = await AdminRepository.getOneParticipantProtocol(payload)
            return response.data.data
        } catch (e) {
            console.log(e)
        }

    }
)

export const getOneJuryProtocol = createAsyncThunk(
    'admin/getOneJuryProtocol',
    async (payload) => {
        try {
            const response = await AdminRepository.getOneJuryProtocol(payload)
            return response.data.data
        } catch (e) {
            console.log(e)
        }

    }
)

export const getEvaluations = createAsyncThunk(
    'admin/getEvaluations',
    async (payload) => {
        try {
            const response = await AdminRepository.getEvaluations(payload)
            return response.data.data
        } catch (e) {
            console.log(e)
        }

    }
)

export const deleteEvaluations = createAsyncThunk(
    'admin/deleteEvaluations',
    async (payload) => {
        try {
            const response = await AdminRepository.deleteEvaluations(payload)
            return response.data.data
        } catch (e) {
            console.log(e)
        }

    }
)

export const updateEvaluations = createAsyncThunk(
    'admin/updateEvaluations',
    async (payload) => {
        try {
            const response = await AdminRepository.updateEvaluations(payload)
            return response.data.data
        } catch (e) {
            console.log(e)
        }

    }
)

export const createParticipant = createAsyncThunk(
    'admin/createParticipant',
    async (payload) => {
        try {
            const response = await AdminRepository.createParticipant(payload)
            return response.data.data
        } catch (e) {
            console.log(e)
        }

    }
)

export const updateParticipant = createAsyncThunk(
    'admin/updateParticipant',
    async (payload) => {
        try {
            const response = await AdminRepository.updateParticipant(payload)
            return response.data.data
        } catch (e) {
            console.log(e)
        }

    }
)

export const createJury = createAsyncThunk(
    'admin/createJury',
    async (payload) => {
        try {
            const response = await AdminRepository.createJury(payload)
            return response.data.data
        } catch (e) {
            console.log(e)
        }

    }
)

export const updateJury = createAsyncThunk(
    'admin/updateJury',
    async (payload) => {
        try {
            const response = await AdminRepository.updateJury(payload)
            return response.data.data
        } catch (e) {
            console.log(e)
        }

    }
)

export const createUser = createAsyncThunk(
    'admin/createUser ',
    async (payload) => {
        try {
            const response = await AdminRepository.createUser(payload)
            return response.data.data
        } catch (e) {
            console.log(e)
        }

    }
)

export const updateUser = createAsyncThunk(
    'admin/updateUser ',
    async (payload) => {
        try {
            const response = await AdminRepository.updateUser(payload)
            return response.data.data
        } catch (e) {
            console.log(e)
        }

    }
)

export const getUser = createAsyncThunk(
    'admin/getUser ',
    async (payload) => {
        try {
            const response = await AdminRepository.getUser(payload)
            return response.data.data
        } catch (e) {
            console.log(e)
        }

    }
)


export const getUserList = createAsyncThunk(
    'admin/getUserList ',
    async (payload) => {
        try {
            const response = await AdminRepository.getUserList(payload)
            return response.data.data
        } catch (e) {
            console.log(e)
        }

    }
)

export const deleteUser = createAsyncThunk(
    'admin/deleteUser ',
    async (payload) => {
        try {
            const response = await AdminRepository.deleteUser(payload)
            return response.data.data
        } catch (e) {
            console.log(e)
        }

    }
)

export const exportCommonProtocol = createAsyncThunk(
    'admin/exportCommonProtocol ',
    async () => {
        try {
            const response = await AdminRepository.exportCommonProtocol()
            return response.data.data.url
        } catch (e) {
            console.log(e)
        }

    }
)

export const exportZipProtocol = createAsyncThunk(
    'admin/exportZipProtocol ',
    async () => {
        try {
            const response = await AdminRepository.exportZipProtocol()
            return response.data.data.url
        } catch (e) {
            console.log(e)
        }

    }
)

export const exportJuryProtocol = createAsyncThunk(
    'admin/exportJuryProtocol ',
    async (payload) => {
        try {
            const response = await AdminRepository.exportJuryProtocol(payload)
            return response.data.data.url
        } catch (e) {
            console.log(e)
        }
    }
)

const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        setToggle: (state, { payload }) => {
            state.isShowProtocol = payload
        },
        clearEvalutions: (state, { payload }) => {
            state.evaluations = null
        },
        clearUrl: (state, { payload }) => {
            state.url = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSettings.fulfilled, (state, { payload }) => {
                state.isShowProtocol = payload.bool_value
            })
        builder
            .addCase(setSettings.fulfilled, (state, { payload }) => {
                state.isShowProtocol = payload.bool_value
            })

        builder
            .addCase(getParticipants.pending, (state, { payload }) => {
                state.isLoading = true
            })
            .addCase(getParticipants.fulfilled, (state, { payload }) => {
                state.participants = payload
                state.isLoading = false

            })
        builder
            .addCase(getJuryList.pending, (state, { payload }) => {
                state.isLoading = true
            })
            .addCase(getJuryList.fulfilled, (state, { payload }) => {
                state.juries = payload
                state.isLoading = false
            })
        builder
            .addCase(deleteParticipant.pending, (state, { payload }) => {
                state.isLoading = true
            })
            .addCase(deleteParticipant.fulfilled, (state, { payload }) => {
                state.participants.splice(payload, 1)
                state.isLoading = false
            })
        builder
            .addCase(deleteJury.pending, (state, { payload }) => {
                state.isLoading = true
            })
            .addCase(deleteJury.fulfilled, (state, { payload }) => {
                state.juries.splice(payload, 1)
                state.isLoading = false
            })
        builder
            .addCase(showParticipant.pending, (state, { payload }) => {
                state.isLoading = true
            })
            .addCase(showParticipant.fulfilled, (state, { payload }) => {
                state.participant = payload
                state.isLoading = false
            })
        builder
            .addCase(showJury.pending, (state, { payload }) => {
                state.isLoading = true
            })
            .addCase(showJury.fulfilled, (state, { payload }) => {
                state.jury = payload
                state.isLoading = false
            })

        builder
            .addCase(getCommonProtocol.pending, (state, { payload }) => {
                state.isLoading = true
            })
            .addCase(getCommonProtocol.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.commonProtocol = payload
            })
        builder
            .addCase(getOneJuryProtocol.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getOneJuryProtocol.fulfilled, (state, { payload }) => {
                state.oneJuryProtocol = payload
                state.isLoading = false
            })
        builder
            .addCase(getEvaluations.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getEvaluations.fulfilled, (state, { payload }) => {

                state.evaluations = payload
                state.isLoading = false
            })
        builder
            .addCase(deleteEvaluations.pending, (state) => {
                state.isLoading = true
            })
            .addCase(deleteEvaluations.fulfilled, (state, { payload }) => {
                state.evaluations = null
                state.isLoading = false
            })
        builder
            .addCase(updateEvaluations.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateEvaluations.fulfilled, (state, { payload }) => {
                state.evaluations = payload
                state.isLoading = false
            })
        builder
            .addCase(getOneParticipantProtocol.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getOneParticipantProtocol.fulfilled, (state, { payload }) => {
                state.oneParticipantProtocol = payload
                state.isLoading = false
            })
        builder
            .addCase(createParticipant.pending, (state) => {
                state.isLoading = true
            })
            .addCase(createParticipant.fulfilled, (state, { payload }) => {
                state.participants = [...state.participants, payload]
                state.isLoading = false
            })
        builder
            .addCase(updateParticipant.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateParticipant.fulfilled, (state, { payload }) => {

                state.participant = payload
                state.isLoading = false
            })

        builder
            .addCase(createJury.pending, (state) => {
                state.isLoading = true
            })
            .addCase(createJury.fulfilled, (state, { payload }) => {
                state.participants = [...state.juries, payload]
                state.isLoading = false
            })
        builder
            .addCase(updateJury.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateJury.fulfilled, (state, { payload }) => {

                state.jury = payload
                state.isLoading = false
            })
        builder
            .addCase(createUser.pending, (state) => {
                state.isLoading = true
            })
            .addCase(createUser.fulfilled, (state, { payload }) => {
                state.users = [...state.users, payload]
                state.isLoading = false
            })
        builder
            .addCase(updateUser.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateUser.fulfilled, (state, { payload }) => {

                state.user = payload
                state.isLoading = false
            })
        builder
            .addCase(getUser.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getUser.fulfilled, (state, { payload }) => {
                state.user = payload
                state.isLoading = false
            })
        builder
            .addCase(getUserList.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getUserList.fulfilled, (state, { payload }) => {

                state.users = payload
                state.isLoading = false
            })
        builder
            .addCase(deleteUser.pending, (state, { payload }) => {
                state.isLoading = true
            })
            .addCase(deleteUser.fulfilled, (state, { payload }) => {
                state.users.splice(payload, 1)
                state.isLoading = false
            })
        builder
            .addCase(exportCommonProtocol.pending, (state, { payload }) => {
                state.isLoading = true
            })
            .addCase(exportCommonProtocol.fulfilled, (state, { payload }) => {
                state.url = payload
                state.isLoading = false
            })
        builder
            .addCase(exportZipProtocol.pending, (state, { payload }) => {
                state.isLoading = true
            })
            .addCase(exportZipProtocol.fulfilled, (state, { payload }) => {
                state.url = payload
                state.isLoading = false
            })
        builder
            .addCase(exportJuryProtocol.pending, (state, { payload }) => {
                state.isLoading = true
            })
            .addCase(exportJuryProtocol.fulfilled, (state, { payload }) => {
                state.url = payload
                state.isLoading = false
            })

    }
})


export default adminSlice.reducer
export const { setToggle, clearEvalutions, clearUrl } = adminSlice.actions