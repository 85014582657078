import instance from "../Service";

export default class AuthRepository {
    static async login(data) {
        return instance.post('/auth/login', data)
    }
    static async logout() {
        return instance.post('/auth/logout')
    }
    static async refresh() {
        return instance.post('auth/refresh')
    }
    static async getSettings() {
        return instance.get(`/auth/settings?name=visible_protocol_to_jury`)
    }
}