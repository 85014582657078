import { lazy } from "react";

const Layout = lazy(() => import("../../containers/Layout"));

const JuryEvaluation = lazy(() => import('../../pages/jury/Evaluation'))
const JuryProtocol = lazy(() => import('../../pages/jury/Protocol'))
const JuryEvaluationDetail = lazy(() => import('../../pages/jury/EvaluationDetail'))

export const juryRouter = [
    {
        path: "jury",
        element: <Layout />,
        children: [
            {
                path: 'evaluations',
                element: <JuryEvaluation />,
            },
            {
                path: 'evaluations/protocols/common',
                element: <JuryProtocol />
            },
            {
                path: 'evaluations/:id/create',
                element: <JuryEvaluationDetail />
            },
        ]
    },
];
