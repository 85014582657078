import {redirect} from "react-router-dom";

export const headerHandler = (role) => {
    if (role === 'admin') {
        return "Администратор"
    } else if (role === 'watcher') {
        return "Наблюдатель"
    } else if (role === 'jury') {
        return "Член жюри"
    }
}

export const routeHandler = (role) => {
    if (role === 'jury') {
        return 'evaluations'
    } else if (role === 'admin') {
        return 'protocols/common'
    } else if (role === 'watcher') {
        return 'protocols/common'
    }
}

export const routeDetection = () => {
    const role = localStorage.getItem('role');
    if(role) {
        return `/${role}/${routeHandler(role)}`
    } else {
        return '/login'
    }
}
