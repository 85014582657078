import instance from "../Service";

export default class AdminRepository {
    static async setSettings(data) {
        return instance.put('/admin/settings', data)
    }
    static async getParticipants() {
        return instance.get('/admin/participants')
    }
    static async getJuryList() {
        return instance.get(`/admin/users/?roles[]=jury`)
    }

    static async deleteParticipant(data) {
        return instance.delete(`/admin/participants/${data}`)
    }
    static async deleteJury(data) {
        return instance.delete(`/admin/users/${data}`)
    }

    static async showParticipant(data) {
        return instance.get(`/admin/participants/${data}`)
    }
    static async showJury(data) {
        return instance.get(`/admin/users/${data}`)
    }

    static async getCommonProtocol(data) {
        return instance.get(`/admin/evaluations/common?sections[]=${data}`)
    }
    static async getOneJuryProtocol(data) {
        return instance.get(`/admin/evaluations/jury/${data.id}?sections[]=${data.section}`)
    }
    static async deleteEvaluations(data) {
        return instance.delete(`/admin/evaluations/participant/${data.participant_id}/jury/${data.id}`)
    }
    static async updateEvaluations(params) {
        console.log(params)
        return instance.put(`/admin/evaluations/participant/${params.participant_id}/jury/${params.id}`, params.data)
    }
    static async getEvaluations(data) {
        return instance.get(`/admin/evaluations/participant/${data.participant_id}/jury/${data.id}`)
    }
    static async getOneParticipantProtocol(data) {
        return instance.get(`/admin/evaluations/participant/${data.id}?sections[]=${data.section}`)
    }

    static async createParticipant(data) {
        return instance.post(`/admin/participants`, data)
    }

    static async updateParticipant(data) {
        return instance.put(`/admin/participants/${data.id}`, data.data)
    }

    static async createJury(data) {
        return instance.post(`/admin/users`, data)
    }

    static async updateJury(data) {
        return instance.put(`/admin/users/${data.id}`, data.data)
    }

    static async createUser(data) {
        return instance.post(`/admin/users`, data)
    }

    static async updateUser(data) {
        return instance.put(`/admin/users/${data.id}`, data.data)
    }
    static async deleteUser(data) {
        return instance.delete(`/admin/users/${data}`)
    }
    static async getUser(data) {
        return instance.get(`/admin/users/${data}`)
    }
    static async getUserList() {
        return instance.get(`/admin/users/?roles[]=watcher&roles[]=admin`)
    }

    static async exportCommonProtocol() {
        return instance.get(`/admin/evaluations/export/link/common`)
    }
    static async exportZipProtocol() {
        return instance.get(`/admin/evaluations/export/link/zip`)
    }
    static async exportJuryProtocol(data) {
        return instance.get(`/admin/evaluations/export/link/jury/${data}`)
    }
}