import { lazy } from "react";

const Layout = lazy(() => import("../../containers/Layout"));

const AdminCommonProtocol = lazy(() => import('../../pages/admin/Protocols/CommonProtocol'))
const AdminParticipantsProtocol = lazy(() => import('../../pages/admin/Protocols/ParticipantsProtocol'))
const AdminJuriesProtocol = lazy(() => import('../../pages/admin/Protocols/JuriesProtocol'))
const AdminJuryList = lazy(() => import('../../pages/admin/Protocols/JuryList'))
const AdminParticipantsList = lazy(() => import('../../pages/admin/Protocols/ParticipantsList'))
const AdminParticipants = lazy(() => import('../../pages/admin/Participantas/Participants'))
const AdminJuries = lazy(() => import('../../pages/admin/Juries/Juries'))
const AdminCreateJury = lazy(() => import('../../pages/admin/Juries/CreateJury'))
const AdminViewJury = lazy(() => import('../../pages/admin/Juries/ViewJury'))
const AdminEditJury = lazy(() => import('../../pages/admin/Juries/EditJury'))
const AdminActivityLog = lazy(() => import('../../pages/admin/ActivityLog'))
const AdminSystemUsers = lazy(() => import('../../pages/admin/SystemUsers/SystemUsers'))
const AdminCreateUser = lazy(() => import('../../pages/admin/SystemUsers/CreateUser'))
const AdminEditUser = lazy(() => import('../../pages/admin/SystemUsers/EditUser'))
const AdminViewUser = lazy(() => import('../../pages/admin/SystemUsers/ViewUser'))
const AdminEvaluationEditing = lazy(() => import('../../pages/admin/EvaluationEditing'))
const AdminEvaluationViewing = lazy(() => import('../../pages/admin/EvaluationViewing'))
const AdminProtocolParticipantsEditing = lazy(() => import('../../pages/admin/ProtocolParticipantsEditing'))
const AdminProtocolParticipantsViewing = lazy(() => import('../../pages/admin/ProtocolParticipantsViewing'))
const AdminCreateParticipant = lazy(() => import('../../pages/admin/Participantas/CreateParticipant'))
const AdminViewParticipant = lazy(() => import('../../pages/admin/Participantas/ViewParticipant'))
const AdminEditParticipant = lazy(() => import('../../pages/admin/Participantas/EditParticipant'))


export const adminRouter = [
    {
        path: 'admin',
        element: <Layout />,
        children: [
            {
                path: 'protocols/common',
                element: <AdminCommonProtocol />
            },
            {
                path: 'protocols/participants',
                element: <AdminParticipantsList />
            },
            {
                path: 'protocols/participants/:participant_id',
                element: <AdminParticipantsProtocol />
            },
            {
                path: 'protocols/juries',
                element: <AdminJuryList />,
            },
            {
                path: 'protocols/juries/:id',
                element: <AdminJuriesProtocol />,
            },
            {
                path: 'protocols/juries/:id/participant/:participant_id/:edit',
                element: <AdminEvaluationEditing />
            },
            {
                path: 'protocols/juries/:id/participant/:participant_id',
                element: <AdminEvaluationViewing />
            },
            {
                path: 'protocols/participants/:participant_id/jury/:id/edit',
                element: <AdminProtocolParticipantsEditing />
            },
            {
                path: 'protocols/participants/:participant_id/jury/:id',
                element: <AdminProtocolParticipantsViewing />
            },
            {
                path: 'participants',
                element: <AdminParticipants />
            },
            {
                path: 'participants/create',
                element: <AdminCreateParticipant />,
            },
            {
                path: 'participants/:id',
                element: <AdminViewParticipant />,
            },
            {
                path: 'participants/:id/edit',
                element: <AdminEditParticipant />,
            },
            {
                path: 'juries',
                element: <AdminJuries />
            },
            {
                path: 'juries/create',
                element: <AdminCreateJury />
            },
            {
                path: 'juries/:id',
                element: <AdminViewJury />
            },
            {
                path: 'juries/:id/edit',
                element: <AdminEditJury />
            },
            {
                path: 'activity-log',
                element: <AdminActivityLog />
            },
            {
                path: 'system-users',
                element: <AdminSystemUsers />
            },
            {
                path: 'system-users/:id',
                element: <AdminViewUser />
            },
            {
                path: 'system-users/:id/edit',
                element: <AdminEditUser />
            },
            {
                path: 'system-users/create',
                element: <AdminCreateUser />
            },
        ],
    },
];
