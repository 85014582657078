import FaceFrownIcon from '@heroicons/react/24/solid/FaceFrownIcon'
import { Link, useSearchParams } from 'react-router-dom';
import {routeDetection} from '../utils/functions'
import {useEffect} from "react";
import {NotificationContainer, NotificationManager} from "react-notifications";
import {removeNotificationMessage} from "../app/slices/headerSlice";

function ErrorPage(props) {
    const [searchParams] = useSearchParams();
    useEffect(() => {
       NotificationManager.error(searchParams.get('message'), searchParams.get('code'))
    }, [])
    return (
        <>
            <div className="hero h-full bg-base-200">
                <div className="hero-content text-gray-400 text-center">
                    <div className="max-w-md">
                        <FaceFrownIcon className="h-40 w-40 inline-block" />
                        <h1 className="text-4xl font-bold">{searchParams.get('code')}</h1>
                        <h3>{searchParams.get('message')}</h3>
                        <Link to={routeDetection()} className='btn btn-link text-secondary'>Вернуться домой</Link>
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </>
    )
}

export default ErrorPage