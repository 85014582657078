
module.exports = Object.freeze({
    MODAL_BODY_TYPES: {
        USER_DETAIL: "USER_DETAIL",
        LEAD_ADD_NEW: "LEAD_ADD_NEW",
        CONFIRMATION: "CONFIRMATION",
        DEFAULT: "",
    },

    RIGHT_DRAWER_TYPES: {
        NOTIFICATION: "NOTIFICATION",
        CALENDAR_EVENTS: "CALENDAR_EVENTS",
    },

    CONFIRMATION_MODAL_CLOSE_TYPES: {
        LEAD_DELETE: "LEAD_DELETE",
        ESTIMATE: "ESTIMATE",
        RESET_ESTIMATE: "RESET_ESTIMATE",
        DELETE_PARTICIPANT: "DELETE_PARTICIPANT",
        DELETE_PARTICIPANT_DETAIL: "DELETE_PARTICIPANT_DETAIL",
        DELETE_JURY: "DELETE_JURY",
        DELETE_JURY_DETAIL: "DELETE_JURY_DETAIL",
        ESTIMATE_EDIT: "ESTIMATE_EDIT",
        RESET_ESTIMATE_JURY_DETAIL: "RESET_ESTIMATE_JURY_DETAIL",
        RESET_ESTIMATE_PARTICIPANT_DETAIL: "RESET_ESTIMATE_PARTICIPANT_DETAIL",
        ESTIMATE_EDIT_JURY: "ESTIMATE_EDIT_JURY",
        ESTIMATE_EDIT_PARTICIPANT: "ESTIMATE_EDIT_PARTICIPANT",
        CREATE_PARTICIPANT: "CREATE_PARTICIPANT",
        UPDATE_PARTICIPANT: "UPDATE_PARTICIPANT",
        CREATE_JURY: "CREATE_JURY",
        UPDATE_JURY: 'UPDATE_JURY',
        CREATE_USER: "CREATE_USER",
        UPDATE_USER: 'UPDATE_USER',
        DELETE_USER: 'DELETE_USER'
    },
});
